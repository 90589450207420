<template>
<div>
  <head-layout
    :head-btn-options="headBtnOptions"
    :head-title="this.type=='edit'?'摄像头编辑':'摄像头新增'"
    @head-save="headSave()"
    @head-save-cancel="headSave(true)"
    @head-cancel="headCancel"
  ></head-layout>
  <el-scrollbar>
    <form-layout
      ref="formLayout"
      :column="formColumn"
      :dataForm="dataForm"
    ></form-layout>
  </el-scrollbar>
</div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {submit,detail} from "@/api/desk/videoPlayerConfig";
import sinoma from "@/util/sinoma"
export default {
  name: "videoPlayerConfigAdd",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {},
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: '名称',
          prop: 'name',
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + '名称',
          disabled: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + '名称',
              trigger: "blur"
            },
          ],
        },
        {
          label: '编码',
          prop: 'code',
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + '编码',
          disabled: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + '编码',
              trigger: "blur"
            },
          ],
        },
        {
          label: "是否启用",
          span: 8,
          prop: "isEnable",
          type: 'select',
          dataType: 'string',
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no",
          disabled: !['add', 'edit'].includes(this.type),
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + '是否启用',
              trigger: "blur"
            },
          ],
          placeholder: this.$t(`cip.md.item.field.isEnable`)
        },
        {
          label: "添加时间",
          span: 8,
          readonly: true,
          prop: "createTime",
          format:"yyyy-MM-dd HH:mm:ss",
          valueFormat:"yyyy-MM-dd HH:mm:ss",
          row: true
        },
        {
          label: '视频流地址',
          prop: "address",
          type: 'textarea',
          span: 16,
          maxlength: 200,
          row: true,
          showWordLimit: true,
          disabled: !['edit', 'add'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + '视频流地址',
              trigger: ["blur", 'change']
            }
          ],
        },
      ]
    },
  },
  created() {
    let {id, type,projectArea} = this.$route.query;
    this.type = type;
    if (this.type != 'add') {
      this.dataForm.id = id
      this.initData()
    }else {
      this.dataForm.createTime = sinoma.toDate(new Date())
    }
    if (projectArea) {
      this.dataForm.projectAreaId = projectArea.id
      this.dataForm.projectAreaName = projectArea.areaName
    }
  },
  methods:{
    initData(){
      detail(this.dataForm.id).then(res=>{
        this.dataForm = res.data.data
      })
    },
    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          submit({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
                this.initData()
              }
            })
            .finally(() => {
              this.$loading().close();
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    }
  }
}
</script>

<style scoped>

</style>
